import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import {
  Layout,
  HeaderBlob,
  Pill,
  BulletListWithIcon,
  ButtonWithIcon,
  Seo,
} from "../components/Shared"
import {
  BoilerRepairs,
  ThermalImaging,
  FaqSection,
  HomeCoverPlans,
} from "../components/Sections"
import { smoothScrollToElementWithId } from "../utilities"

const HomeCover = ({ data }) => (
  <Layout>
    <Seo
      url={data.site && data.site.siteMetadata.url + "/home-cover-plans"}
      title={data.headerAndSeo.seo && data.headerAndSeo.seo.title}
      description={data.headerAndSeo.seo.metaDescription.metaDescription}
      image={
        data.headerAndSeo.seo &&
        data.headerAndSeo.seo.image &&
        data.headerAndSeo.seo.image.file &&
        data.headerAndSeo.seo.image.file.url
      }
    />
    <HeaderBlob
      image={data.headerImage.image.fluid}
      imageDescription={data.headerImage.image.title}
    >
      <h1 className="heading-extra-large">{data.headerAndSeo.headerTitle}</h1>
      <ReactMarkdown
        source={data.headerAndSeo.headerParagraph.headerParagraph}
      />
      <Pill text="Included with our plans" />
      <BulletListWithIcon bullets={data.headerAndSeo.headerBullets} />
      <div className="header-buttons">
        <ButtonWithIcon
          icon="arrow-down"
          onClick={() => smoothScrollToElementWithId("home-cover-plans")}
        >
          Choose your plan
        </ButtonWithIcon>
      </div>
    </HeaderBlob>
    <HomeCoverPlans
      plans={data.allContentfulHomeCoverPlans.edges}
      id="home-cover-plans"
    />
    {data.headerAndSeo.faqs && <FaqSection faqs={data.headerAndSeo.faqs} />}
    <BoilerRepairs coverPage={true} scrollToId="home-cover-plans" />
    <ThermalImaging coverPage={true} scrollToId="home-cover-plans" />
  </Layout>
)

export default HomeCover

export const pageQuery = graphql`
  query HomeCoverContent {
    site {
      siteMetadata {
        url
      }
    }
    headerAndSeo: contentfulPages(page: { eq: "Home Cover Landing" }) {
      contentful_id
      page
      seo {
        image {
          file {
            url
          }
        }
        metaDescription {
          metaDescription
        }
        title
      }
      headerTitle
      headerParagraph {
        headerParagraph
      }
      headerBullets
      faqs {
        answer {
          answer
        }
        question
        id
      }
    }
    headerImage: contentfulPageSections(
      contentful_id: { eq: "4dSjt6A1e062LBi2qiBzuV" }
    ) {
      image {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
    allContentfulHomeCoverPlans(sort: { order: ASC, fields: createdAt }) {
      edges {
        node {
          contentful_id
          CP12
          boilerRepair
          contentful_id
          electricalRepairs
          freeSystemFlush
          heatingRepairs
          monthlyPrice
          maxClaimsPerYear
          name
          plumbingRepairs
          replacementBoilerOver7Years
          replacementBoilerUnder7Years
          shortDescription
          defaultCalloutFee {
            id
            calloutFee
          }
        }
      }
    }
  }
`
